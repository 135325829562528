import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ActionButton } from "./_Shared";

import LeuliPic from "./Leuli/leuli.png";

const Image = styled.img`
    max-height: 50vh;
    width: auto;
    display: block;
    margin-bottom: 2rem;
`;

const Container = styled.div`
    text-align: center;
`;

export default () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3, delay: 1 }}
        >
            <Container>
                <Image src={LeuliPic} />
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 3, delay: 2 }}
                ></motion.div>
                <ActionButton
                    style={{ maxWidth: "10rem", margin: "0 auto" }}
                    href="https://aoauli.acca.melbourne"
                    target="_blank"
                    tabIndex="1"
                >
                    Open AOAULI
                </ActionButton>
            </Container>
        </motion.div>
    );
};
