import styled from "styled-components";

const ContentBox = styled.div`
    font-size: 3rem;
    height: 40vh;
    display: grid;
    place-items: center;
    text-align: center;
    font-family: var(--wide-font);
`;

const ActionButton = styled.a`
    font-size: 1rem;
    font-family: var(--wide-font);
    font-weight: 500;
    border: 2px solid var(--text-color);
    background-color: var(--bg-color);
    color: var(--text-color);
    display: block;
    padding: 0.5rem 1rem 0.5rem;
    border-radius: 4px;
    margin: 1.5rem 0;
    box-shadow: 0 0 0px 0px var(--text-color);
    transition: all 0.2s ease;
    &:hover,
    &:focus {
        box-shadow: 0 0 0px 8px var(--text-color);
    }
`;

export { ContentBox, ActionButton };
