import React, { useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

import content from "./Content";

const PageDiv = styled.div`
    height: 100%;
    left: ${(props) => (props.isPushed ? "-75%" : "0")};
    position: fixed;
    top: 0;
    transition: left 0.3333s ease;
    width: 100%;
    @media (max-width: 768px) {
        left: ${(props) => (props.isPushed ? "-90%" : "0")};
    }
`;

const PageName = styled(motion.div)`
    bottom: 1.5rem;
    font-family: var(--wide-font);
    left: 1.5rem;
    position: absolute;

    @media (max-width: 768px) {
        bottom: 1rem;
        left: 1rem;
        width: 50%;
    }
`;
const PageAbout = styled(motion.button)`
    bottom: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    @media (max-width: 768px) {
        bottom: 1rem;
        right: 1rem;
        width: 30%;
        text-align: right;
    }
    &:focus {
        text-decoration: underline;
    }
`;

const PageInfoDiv = styled.div`
    background-color: #666; /* var(--bg-color); */
    color: white;
    border-left: 1px solid var(--text-color);
    display: block;
    font-family: var(--wide-font);
    font-size: 1.333rem;
    height: 100%;
    left: ${(props) => (props.isPushed ? "25%" : "100%")};
    overflow: auto;
    padding: 1.2rem 1.5rem 50vh;
    position: fixed;
    top: 0;
    transition: left 0.3333s ease, width 0.3333s ease;
    width: ${(props) => (props.isPushed ? "75%" : "75%")};

    @media (max-width: 768px) {
        left: ${(props) => (props.isPushed ? "10%" : "100%")};
        width: 90%;
        font-size: 1.1rem;
    }

    h1 {
        margin-bottom: 10rem;
    }

    p {
        max-width: 25em;
        text-indent: 4rem;
    }
`;

export default ({ setTheme, isPushed, setIsPushed }) => {
    const { slug } = useParams();
    const infoRef = useRef();
    const page = content.filter((item) => item.slug === slug)[0];

    useEffect(() => {
        const theme = content.filter((item) => item.slug === slug)[0].theme;
        if (theme) {
            setTheme(theme);
        }
        return () => {};
    }, [slug, setTheme]);

    useEffect(() => {
        return () => setIsPushed(false);
    }, [setIsPushed]);

    useEffect(() => {
        if (!isPushed) {
            infoRef.current.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
        }
    }, [isPushed]);

    return (
        <PageDiv isPushed={isPushed}>
            <Helmet>
                <title>{`${page.title}`}</title>
            </Helmet>
            <AnimatePresence>
                <motion.div
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    key={`${page.slug}2`}
                    style={{ height: "100%" }}
                    transition={{ delay: 0.2 }}
                >
                    {page.content}
                </motion.div>

                <PageName
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 0, opacity: 0 }}
                    initial={{ y: 100, opacity: 0 }}
                    key={`${page.slug}1`}
                    transition={{ delay: 0.1, type: "spring", damping: 30 }}
                >
                    {page.name}
                    <br />
                    <em>{page.title}</em>
                </PageName>

                <PageAbout
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ y: 100, opacity: 0 }}
                    key={`${page.slug}3`}
                    onClick={() => setIsPushed(!isPushed)}
                    transition={{ delay: 0.3, type: "spring", damping: 30 }}
                >
                    About this work →
                </PageAbout>
            </AnimatePresence>
            <PageInfoDiv ref={infoRef} isPushed={isPushed}>
                {page.about}
            </PageInfoDiv>
        </PageDiv>
    );
};
