import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import content from "./Content";
import { useWindowWidth } from "@react-hook/window-size";
import { useScrollYPosition } from "react-use-scroll-position";

import LogoImage from "./AccaOpenLogo.svg";

const Menu = styled(motion.div)`
    position: fixed;
    top: 0;
    left: ${(props) => (props.isPushed ? "-75%" : "0")};
    transition: left 0.3333s ease;
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 3;

    @media (max-width: 768px) {
        left: ${(props) => (props.isPushed ? "-90%" : "0")};
        padding: 1rem;
    }
`;

const Logo = styled(motion.div)`
    flex: 0 0 6rem;
    font-family: var(--wide-font);
    img {
        height: 1rem;
        width: auto;
        filter: var(--logo-filter);
    }
`;

const MenuItems = styled(motion.div)`
    display: flex;
    justify-content: center;

    a:focus {
        outline: none;
        text-decoration: underline;
    }

    @media (min-width: 769px) {
        > div {
            max-width: 10rem;
            margin: 0 1.5vw;
        }
    }

    @media (max-width: 768px) {
        display: block;
        font-size: 1.24rem;
        flex-direction: column;
        position: fixed;
        top: ${(props) => (props.isOpenMobile ? "0" : "-100%")};
        left: 0;
        background: var(--bg-color);
        overflow: auto;
        width: 100%;
        max-height: 100%;
        padding: 2rem 1rem 1rem;
        border-bottom: 1px solid var(--text-color);
        z-index: 2;
        transition: top 0.3s ease-out;
        text-align: left;

        > div {
            max-width: 100%;
            margin-bottom: 1rem;
            br {
                display: none;
            }
        }
    }
`;

const MenuOpener = styled(motion.div)`
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    padding: 1rem;
    @media (max-width: 768px) {
        display: flex;
        right: ${(props) => (props.isPushed ? "90%" : "0")};
        transition: right 0.3333s ease;
    }
`;

const MenuUnderlay = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: flex;
        z-index: 1;
        background: rgba(255, 255, 255, 0.05);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }
`;

const Info = styled(motion.div)`
    flex: 0 0 6rem;
    text-align: right;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    letter-spacing: 1px;
    @media (max-width: 768px) {
        display: none;
    }
`;

const list = {
    visible: {
        opacity: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
        },
    },
    hidden: {
        opacity: 1,
        transition: {
            when: "afterChildren",
        },
    },
};

const childAnimation = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
};

const MobileAccaLink = styled.a`
    display: block;
    margin-top: 3rem;
    font-size: 0.85rem;
    font-weight: 500;
    letter-spacing: 1px;
`;

export default ({ isPushed }) => {
    const [isOpenMobile, setIsOpenMobile] = useState(false);
    const location = useLocation();
    const winWidth = useWindowWidth();
    const scrollY = useScrollYPosition();

    const isMenuVisible = location.pathname !== "/";
    const isMobile = winWidth <= 768;
    const hideNumber = 96;

    const opacityForMenu =
        hideNumber - scrollY < 0 ? 0 : (hideNumber - scrollY) / hideNumber;

    return (
        <Menu
            isPushed={isPushed}
            initial={"hidden"}
            animate={"visible"}
            variants={list}
            key={"menu"}
        >
            <Logo key={"logo"} variants={childAnimation}>
                <NavLink to="/" style={{ opacity: opacityForMenu }}>
                    <img src={LogoImage} alt="ACCA Open" />
                </NavLink>
            </Logo>
            <MenuItems
                key={"menu"}
                animate={isMenuVisible ? "visible" : "hidden"}
                variants={list}
                isOpenMobile={isOpenMobile}
            >
                {content.map((item) => (
                    <motion.div key={item.slug} variants={childAnimation}>
                        <NavLink
                            tabIndex={isMenuVisible ? 0 : -1}
                            activeStyle={{
                                fontWeight: "500",
                            }}
                            to={`/work/${item.slug}`}
                            onClick={() => setIsOpenMobile(false)}
                        >
                            {item.menuName}
                        </NavLink>
                    </motion.div>
                ))}
                {isMobile && isMenuVisible && isOpenMobile && (
                    <MobileAccaLink
                        href="https://acca.melbourne"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ACCA ↗
                    </MobileAccaLink>
                )}
            </MenuItems>

            {!isOpenMobile ? (
                isMenuVisible && (
                    <MenuOpener
                        variants={childAnimation}
                        onClick={() => setIsOpenMobile(true)}
                        isPushed={isPushed}
                    >
                        Menu
                    </MenuOpener>
                )
            ) : (
                <MenuUnderlay onClick={() => setIsOpenMobile(false)} />
            )}

            <Info key={"info"} variants={childAnimation}>
                <a
                    href="http://acca.melbourne"
                    tabIndex="20"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ opacity: opacityForMenu }}
                >
                    <strong>ACCA ↗</strong>
                </a>
            </Info>
        </Menu>
    );
};
