import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ActionButton } from "./_Shared";
import ZannyPic from "./Zanny/zanny.jpg";

const Image = styled.img`
    max-height: 50vh;
    width: auto;
    display: block;
    margin-bottom: 2rem;
`;

const Container = styled.div`
    text-align: center;
`;

export default () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3, delay: 1 }}
        >
            <Container>
                <Image src={ZannyPic} />
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 3, delay: 2 }}
                ></motion.div>
                <ActionButton
                    style={{ maxWidth: "14rem", margin: "0 auto" }}
                    href="http://magicmountains.art"
                    target="_blank"
                    tabIndex="1"
                >
                    Go to Magic Mountains
                </ActionButton>
            </Container>
        </motion.div>
    );
};
