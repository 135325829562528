import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import { useWindowSize } from "@react-hook/window-size";
import { Link } from "react-router-dom";
import { ContentBox, ActionButton } from "./_Shared";
import * as animationData from "./MaddyTim/data.json";

const LottieBox = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {
        height: 75%;
    }
    z-index: -1;
`;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const Caption = styled.div`
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
`;

export default () => {
    const [width, height] = useWindowSize();
    const boxSize = width > 768 ? height : width * 1.5;
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 2 }}
        >
            <LottieBox>
                <Lottie
                    aria-hidden={true}
                    options={defaultOptions}
                    height={boxSize}
                    width={boxSize}
                />
                <Caption>
                    How much time do we have? The title unfolds over 8 seconds
                    and takes as its central axis the letter ‘I’ in the middle
                    line (serendipity) as a quasi-clock hand, gradually
                    revealing the title through a clockwise motion. We are
                    waiting together.
                </Caption>
            </LottieBox>
            <ContentBox
                aria-hidden={true}
                style={{ height: width > 768 ? "80vh" : "40vh" }}
            />
            <div>
                <ActionButton
                    as={Link}
                    to="/how-much-time"
                    style={{ borderWidth: "1px", fontWeight: 400 }}
                    tabIndex="1"
                >
                    Stream
                </ActionButton>
            </div>
        </motion.div>
    );
};
