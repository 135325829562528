import React, { useState, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import NeighbourVideo from "./Amrita/Neighbour.mp4";

import { ContentBox } from "./_Shared";

const Video = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &::before {
        position: fixed;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 6rem;
        backdrop-filter: blur(2px);
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 80%
        );
    }
    &::after {
        position: fixed;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 7rem;
        backdrop-filter: blur(2px);
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }
`;
const UnmuteButton = styled.button`
    position: fixed;
    bottom: 1rem;
    border-radius: 24px;
    font-size: 1.5rem;
    text-align: center;
    left: 49%;
    padding: 0.5rem;

    &:focus {
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 12px white;
    }
`;

export default () => {
    const [isMuted, setIsMuted] = useState(true);
    const buttonRef = useRef();
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 2 }}
        >
            <Video>
                <video
                    src={NeighbourVideo}
                    autoPlay
                    playsInline
                    loop
                    muted={isMuted}
                />
            </Video>
            <ContentBox></ContentBox>
            <UnmuteButton
                ref={buttonRef}
                aria-label={isMuted ? "Turn on audio" : "Turn off audio"}
                onClick={() => {
                    setIsMuted(!isMuted);
                    buttonRef.current.blur();
                }}
            >
                {isMuted ? "🔇" : "🔈"}
            </UnmuteButton>
        </motion.div>
    );
};
