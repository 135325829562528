import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Helmet from "react-helmet";
import { useWindowWidth } from "@react-hook/window-size";

const Overlay = styled.div`
    background: white;
    color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
        width: 100%;
        height: 100%;
    }
`;

const formatDate = (d) =>
    [
        d.getFullYear(),
        (d.getMonth() + 1).toString().padStart(2, "0"),
        d.getDate().toString().padStart(2, "0"),
    ].join("");

export default () => {
    const [embedId, setEmbedId] = useState();
    const winWidth = useWindowWidth();

    useEffect(() => {
        fetch("https://acca.melbourne/custom/redirect.php", {})
            .then((response) => response.text())
            .then((data) => setEmbedId(data.replace(/.*?v=(.*)/g, "$1")));
    }, []);

    const d = new Date();
    const check = parseInt(formatDate(d));

    return (
        <Overlay showControls={winWidth <= 768}>
            <Helmet>
                <title>How much time do we have?</title>
            </Helmet>
            {check < 20200928 ? (
                <>
                    {embedId && (
                        <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${embedId}?autoplay=1&controls=0`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="video"
                        ></iframe>
                    )}{" "}
                </>
            ) : (
                <div style={{ maxWidth: "20rem" }}>
                    Our time has come to an end. This work faded from view on
                    September 28, with the finish of Stage 4 restrictions in
                    Melbourne.
                    <br />
                    <Link to="/">←</Link>
                </div>
            )}
        </Overlay>
    );
};
