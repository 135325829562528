import React, { useState } from "react";
import styled from "styled-components";

import { ContentBox } from "./_Shared";

import bgImage from "./Archie/grid.png";
import Head1 from "./Archie/Head1.svg";
import Head2 from "./Archie/Head2.svg";
import Head3 from "./Archie/Head3.svg";
import Head4 from "./Archie/Head4.svg";
import arrowImg from "./Archie/Line.svg";

const Background = styled.div`
    background-image: url(${bgImage});
    background-position: 50%;
    background-size: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
`;

const HeadsBoxDiv = styled.div`
    height: 100vh;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    z-index: 0;
`;
const Invisible = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 175%;
    @media screen and (min-height: 1024px) {
        flex: 0 0 160%;
    }
`;

const PlayerScreen = styled.div`
    height: 100vh;
    width: 100vw;
    flex: 0 0 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    iframe {
        max-width: 640px;
        max-height: 372px;
    }
`;
const Arrow = styled.img`
    height: auto;
    width: 1rem;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    opacity: ${(props) => (props.showMe ? 1 : 0)};
    transition: opacity 2s ease;

    @media (max-width: 640px) {
        width: 0.75rem;
    }

    @media (max-width: 969px) {
        right: 5%;
    }

    @media (min-width: 970px) {
        right: 15%;
    }

    @media (min-width: 1440px) {
        right: 20%;
    }
`;

const Image = styled.img`
    position: fixed;

    pointer-events: none;
    @media screen and (max-width: 767px) {
        width: 140vw;
        height: auto;
        left: 50%;
        transform: translate(-50%);
    }
    @media screen and (min-width: 768px) {
        height: 95vh;
        width: auto;
        top: 0;
        left: 45%;
        transform: translate(-50%);
    }
`;

const Heads = [Head1, Head2, Head3, Head4];

const HeadsBox = () => {
    const [margin, setMargin] = useState(1);
    return (
        <HeadsBoxDiv
            onScroll={(e) => {
                const marginCalc = 1 - e.target.scrollTop / window.innerHeight;
                setMargin(marginCalc > 0 ? marginCalc : 0);
            }}
        >
            <Arrow showMe={margin !== 0} src={arrowImg} />
            {Heads.map((head, index) => {
                const marginToApply = `${index * margin * 40}%`;
                return (
                    <Image
                        key={head}
                        src={head}
                        style={{
                            top: marginToApply,
                            transform: `translate(-50%, ${margin * -25}%)`,
                        }}
                    />
                );
            })}
            <Invisible />
            <PlayerScreen>
                <iframe
                    width="100%"
                    height="450"
                    scrolling="no"
                    title="Archie Barry"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1136688829&color=%23bcbcbc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=true"
                ></iframe>
            </PlayerScreen>
        </HeadsBoxDiv>
    );
};

export default () => {
    return (
        <>
            <div>
                <Background />
                <HeadsBox />
                <ContentBox />
            </div>
        </>
    );
};
