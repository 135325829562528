import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import content from "./Content";
import Logos from "./Logos.png";

const ParentContainer = styled.div`
    margin: 0rem auto 6rem;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr minmax(300px, 34%);
    grid-gap: 3rem;
    width: 100%;
    max-width: 1440px;
    font-family: var(--wide-font);

    em {
        font-style: normal;
    }
    p {
        margin-bottom: 1rem;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }
`;

const MainText = styled.div`
    font-size: 1.25rem;
    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

const Sidebar = styled.div`
    margin-top: 0.1rem;
    font-size: 0.85rem;

    em {
        opacity: 0.5;
    }

    li {
        margin-bottom: 0.5rem;
    }

    img {
        width: 100%;
        height: auto;
        max-width: 420px;
        margin: 3rem auto;
        display: block;
    }
`;

const ListOfWorks = styled(motion.div)`
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    min-height: 90vh;
    margin: 0 auto;
    padding: 5vh 2rem 0;
    max-width: 780px;
    @media (max-width: 768px) {
        font-size: 1.25rem;
        padding: 20vw 1rem 0;
    }
`;

const ListItem = styled(motion.custom(Link))`
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom: 0.5em;

    > div {
        flex: 1 1 50%;
        margin: 0 0.25em;
        @media (min-width: 769px) {
            &:first-child {
                text-align: right;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        > div {
            margin: 0;
        }
    }

    color: ${(props) => (props.isActive ? "inherit" : "rgba(0,0,0,0.6)")};
    :hover,
    :focus {
        text-decoration: underline;
    }
`;

const Tiny = styled.div`
    font-size: 0.5rem;
`;

const list = {
    visible: {
        opacity: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
        },
    },
    hidden: {
        opacity: 1,
        transition: {
            when: "afterChildren",
        },
    },
};

const childAnimation = {
    hidden: { opacity: 0, y: 5 },
    visible: { opacity: 1, y: 0 },
};

export default ({ setTheme }) => {
    useEffect(() => {
        const number = Math.round(Math.random());

        setTheme([["#01FFFD", "#00FF02"][number], "black"]);
    }, [setTheme]);
    return (
        <>
            <ListOfWorks initial={"hidden"} animate={"visible"} variants={list}>
                {content.map((item) => (
                    <ListItem
                        key={item.slug}
                        isActive={item.active}
                        variants={childAnimation}
                        to={`/work/${item.slug}`}
                    >
                        <div>{item.name}</div>
                        <div>
                            <strong>{item.title}</strong>
                            <Tiny>{!item.active && item.date}</Tiny>
                        </div>
                    </ListItem>
                ))}
            </ListOfWorks>
            <ParentContainer>
                <MainText>
                    <p>
                        ACCA Open is a new series of contemporary art projects
                        commissioned for the digital realm. Initially conceived
                        as a way for ACCA to continue to work with and support
                        contemporary artists during COVID-19-related gallery
                        closures and disruptions, ACCA Open began as an
                        open-call invitation to Australian artists from all
                        backgrounds, career levels and practices to submit ideas
                        for projects that could be presented through digital
                        platforms.
                    </p>

                    <p>
                        One of the intentions of the series was to support
                        artists to develop their practice in new ways, and we
                        are delighted to announce six new commissions by
                        participating artists Archie Barry, Zanny Begg, Léuli
                        Eshrāghi, Madeleine Flynn and Tim Humphrey, Amrita Hepi
                        and Sam Lieblich, and Sean Peoples. Traversing the
                        fields of artificial intelligence, sound, animation,
                        video and archives, their works speak to the breadth of
                        possibility working in the digital realm, and respond to
                        the unusual times and cultural conditions in which they
                        are produced.
                    </p>

                    <p>
                        Madeleine Flynn and Tim Humphrey explore the endless
                        ambience and elasticity of time, while Amrita Hepi and
                        Sam Lieblich deploy language, movement and neuroscience
                        to consider the increasing prevalence of algorithmic
                        subjectivity. Archie Barry’s project is oriented towards
                        the affective realms of sound and music, away from
                        ocular-centric presence, while Zanny Begg’s filmic
                        narrative presents psycho-philosophical reflections on
                        histories of isolation, contagion and quarantine. Sean
                        Peoples and Léuli Eshrāghi draw on cosmology and the
                        digital archive as an open source for speculative
                        fiction and historical re-interpretation, and as a
                        meeting place and a keeping place for ancestral and
                        other forms of knowledge.
                    </p>
                    <p>
                        The works commissioned for ACCA Open also support
                        development of ACCA’s programming beyond the gallery
                        walls, offering audiences and viewers multiple ways to
                        engage with contemporary art through our digital
                        platforms.
                    </p>
                </MainText>
                <Sidebar>
                    <p>
                        <em>A project by</em>
                        <br />
                        <a
                            href="//acca.melbourne/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Australian Centre for
                            <br />
                            Contemporary Art
                        </a>
                    </p>
                    <p>
                        <em>Artists</em>
                        <br />
                        Archie Barry
                        <br />
                        Zanny Begg
                        <br />
                        Léuli Eshrāghi
                        <br />
                        Madeleine Flynn <br />
                        &amp; Tim Humphrey
                        <br />
                        Amrita Hepi <br />
                        &amp; Sam Lieblich
                        <br />
                        Sean Peoples
                    </p>
                    <p>
                        <em>Curatorial team</em>
                        <br />
                        Max Delany
                        <br />
                        Annika Kristensen
                        <br />
                        Miriam Kelly
                    </p>
                    <p>
                        <em>Digital publishing, consultation, design</em>
                        <br />
                        Rowan McNaught
                    </p>
                    <p>
                        <em>Selection panel</em>
                        <ul>
                            <li>
                                Max Delany
                                <br />
                                Artistic Director/CEO, ACCA
                            </li>
                            <li>
                                Annika Kristensen
                                <br />
                                Senior Curator, ACCA
                            </li>
                            <li>
                                Miriam Kelly
                                <br />
                                Curator, ACCA
                            </li>
                            <li>
                                Bianca Winataputri
                                <br />
                                Public Programs Coordinator, ACCA
                            </li>
                            <li>
                                Fayen d’Evie
                                <br />
                                Artist, academic, publisher and ACCA Board
                                member
                            </li>
                            <li>
                                Rowan McNaught
                                <br />
                                Artist and publisher
                            </li>
                        </ul>
                    </p>

                    <p>
                        <a href="https://acca.melbourne/neighbour-terms">
                            Notices &amp; Privacy Statement »
                        </a>
                    </p>

                    <p>
                        ACCA Open commissions are supported in part by Creative
                        Victoria’s Strategic Investment Fund. ACCA also
                        acknowledges the ongoing support of Creative Victoria,
                        Australia Council for the Arts, Visual Arts and Craft
                        Strategy, and the City of Melbourne.
                    </p>
                    <p>
                        <img src={Logos} alt="Supporter Logos" />
                    </p>
                </Sidebar>
            </ParentContainer>
        </>
    );
};
