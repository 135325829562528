import { createGlobalStyle } from "styled-components";
import reset from "styled-reset-advanced";

export default createGlobalStyle`
    ${reset}

    :root {
        --bg-color: black;
        --text-color: white;
        --normal-font: forma-djr-text, sans-serif;
        --wide-font: forma-djr-text, sans-serif;
    }

    html {
        animation: Gradient 45s ease infinite;
        background: var(--bg-color);
        background-size: 200% 200%;
        color: var(--text-color);
        font-family: var(--normal-font);
        font-size: calc(125% + 0.25vw);
        height: 100%;
        transition: background-color 2s ease-out, color 0.5s ease-out;
        width: 100%;
        letter-spacing: 0.5px;
        @media (max-width: 767px) {
            font-size: calc(100% + 0.7vw);
        }
        @media (min-width:768px) and (max-width: 1279px) {
            font-size: calc(100% + 0.25vw);
        }
    }

    em { 
        font-style: italic;
    }

    strong {
        font-weight: 500;
    }

    html body {
        line-height: 1.2;
    }

    a:hover {
        opacity:0.8;
    }

    @keyframes Gradient {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
`;
