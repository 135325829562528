import React, { useState } from "react";
import Analytics from "react-router-ga";
import Helmet from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";
import BaseStyle from "Components/BaseStyle";
import Menu from "Components/Menu";
import WorkPage from "Components/WorkPage";
import AboutPage from "Components/AboutPage";
import HowMuchTime from "Components/HowMuchTime";
import { useWindowWidth } from "@react-hook/window-size";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

const IsPushedDisable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(12px);
    width: 25%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
    background: rgba(255, 255, 255, 0);
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }

    @media screen and (max-width: 768px) {
        width: 11%;
    }
`;

const ColoursTheme = createGlobalStyle`

    body {
        color: ${(props) => props.theme[1]};
        transition: color 0.3s ease;
    }

    :root {
        --bg-color: ${(props) => props.theme[0]};
        --text-color: ${(props) => props.theme[1]};
        --logo-filter : ${(props) =>
            props.theme[1] === "#fff" ? "invert()" : "none"};
    } 
`;

const Routes = () => {
    const [isPushed, setIsPushed] = useState(false);
    const [theme, setTheme] = useState([
        "linear-gradient(90deg, #01FFFD 0%, #01FF8D 100%), #01FFFD",
        "black",
    ]);

    const width = useWindowWidth();

    return (
        <>
            <Helmet defaultTitle="ACCA Open" titleTemplate="%s • ACCA Open" />
            <ColoursTheme theme={theme} />
            <Router>
                <Analytics id="UA-6339735-3">
                    <Menu isPushed={isPushed} />
                    <Switch>
                        <Route path={"/work/amrita-hepi-sam-leiblich"}>
                            <Redirect to="/work/amrita-hepi-sam-lieblich" />
                        </Route>
                        <Route path={"/work/:slug"}>
                            <WorkPage
                                isPushed={isPushed}
                                setIsPushed={setIsPushed}
                                theme={theme}
                                setTheme={setTheme}
                            />
                        </Route>
                        <Route path={"/how-much-time"}>
                            <HowMuchTime />
                        </Route>
                        <Route>
                            <AboutPage setTheme={setTheme} />
                        </Route>
                    </Switch>
                </Analytics>
            </Router>
            {isPushed && (
                <IsPushedDisable onClick={() => setIsPushed(false)}>
                    ← {width > 768 && "Back to work"}
                </IsPushedDisable>
            )}
        </>
    );
};

export default () => (
    <>
        <BaseStyle />
        <Routes />
    </>
);
