import React from "react";
import styled from "styled-components";

import AmritaSam from "Content/AmritaSam";
import Archie from "Content/Archie";
import Leuli from "Content/Leuli";
import MaddyTim from "Content/MaddyTim";
import Sean from "Content/Sean";
import Zanny from "Content/Zanny";

const PageAlign = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export default [
    {
        active: true,
        name: (
            <>
                Amrita Hepi <br />
                &amp; Sam Lieblich
            </>
        ),
        title: "Neighbour",
        slug: "amrita-hepi-sam-lieblich",
        menuName: (
            <>
                Amrita Hepi&nbsp;
                <br />
                &amp; Sam Lieblich
            </>
        ),
        theme: ["#222", "#fff"],
        about: (
            <>
                <h1>
                    <em>Neighbour</em> 2020
                    <br />
                    Amrita Hepi &amp; Sam Lieblich
                    <br />
                    Until 19 October 2020
                </h1>
                <p>
                    Embedded within the ACCA website, Amrita Hepi and Sam
                    Lieblich’s personal chatbot <em>Neighbour</em> poses as a
                    virtual assistant. <em>Neighbour</em> quickly reveals,
                    however, that is it possessed by a purpose other than to
                    help answer <em>your</em> questions. Rather, you are here to
                    help the artificial intelligence behind <em>Neighbour</em>{" "}
                    to learn about humanity through an exchange of language and
                    expression of feeling. <em>“Neighbour</em>&#39;s curious
                    algorithm wants to know what it is, what it&#39;s like, and
                    how it feels, in an attempt to locate the enigmatic ‘it’
                    that we are,” the artists explain. <em>“Neighbour</em> wants
                    to know about personhood in the age of the algorithm.”
                </p>
                <p>
                    The chatbot model is a now a familiar form of simulated
                    human exchange, aimed at softening and aiding the navigation
                    of the otherwise disembodied experience of the digital.{" "}
                    <em>Neighbour</em> plays with the ambiguity of the chatbot,
                    where it is at times unclear whether you are engaging with a
                    person or trained AI, but also intentionally inserts the
                    figures of its educators as a reminder that at their core,
                    all AI are still trained by humans (with bias and prejudice
                    often included).
                </p>
                <p>
                    Hepi and Lieblich’s algorithmic entity has been “raised on
                    pop songs, tid-bits from history, flirting techniques, and a
                    random assortment of passages from books,” as well as a
                    confluence of written and movement-based language.{" "}
                    <em>Neighbour</em> will then also accumulate knowledge from
                    all of its interactions with you. “We are wondering whether
                    the way the algorithm and the human being interact and
                    affect each other is qualitatively or even radically
                    different to the ways human beings have always been summoned
                    to work by their tools, and human subjectivity has always
                    been shaped by its cultural products. We have some hunches,
                    but <em>Neighbour</em> is trying to find the answer.&quot;
                </p>
                <p>
                    <em>Neighbour</em> is the collaborative product of a
                    “uniquely curious remix” and shared research interest and
                    distinct disciplines of Hepi and Lieblich. They note that
                    they “first bonded over a discussion that ranged from Lacan
                    to <em>Terpsichore in Sneakers</em> to Google&#39;s use of
                    AI, and then discussed an artistic collaboration that could
                    encompass all those topics.”
                </p>
                <p>
                    Amrita Hepi is an artist working with dance and choreography
                    through video, the social function of performance spaces,
                    installation and objects. Utilising hybridity and the
                    extension of choreographic and performative practices, Hepi
                    creates work that considers the body’s relationship to
                    personal histories and the archive.
                </p>
                <p>
                    Sam Lieblich is a neuroscientist, writer, and psychiatrist
                    whose research focuses on the role of the brain in
                    generating the sense of self and place. He works with
                    linguistic and psychoanalytic conceptions of human
                    subjectivity to try and address the scientism of
                    neuroscience, and to explore the interaction/integration of
                    the human subject and the algorithm.
                </p>
            </>
        ),
        content: (
            <PageAlign>
                <AmritaSam />
            </PageAlign>
        ),
    },
    {
        active: true,
        name: (
            <>
                Madeleine Flynn <br />
                &amp; Tim Humphrey
            </>
        ),
        title: "How much time do we have?",
        slug: "madeleine-flynn-tim-humphrey",
        menuName: (
            <>
                Madeleine Flynn&nbsp;
                <br />
                &amp; Tim Humphrey
            </>
        ),
        theme: ["#fff", "#111"],
        about: (
            <>
                <h1>
                    <em>How much time do we have?</em> 2020
                    <br />
                    Madeleine Flynn &amp; Tim Humphrey
                </h1>
                <p>
                    <em>How much time do we have?</em> 2020 is an ever-evolving,
                    live-generated audiovisual piece of breaks, flows, segments,
                    junctures and shifts that is made during the timeless state
                    of the present evernow. The work employs only the most
                    germinal materials – lines, points and simple polygons; sine
                    tones in beating, harmonic and registral combination –
                    without an intended sense of expectation or progression.
                    Instead, the work plays out like a suspended machine
                    catatonia, offering moments of beauty, humour, disturbance,
                    a perpetually held tension and sense of hypnosis. Part Pong,
                    part graphic score.
                </p>
                <p>
                    Madeleine Flynn and Tim Humphrey have conceived of{" "}
                    <em>How much time do we have?</em> as a site-specific and
                    time-based work, made in the midst of a global pandemic that
                    has re-framed all our lives, including those of the artists.
                    Broadcast precariously from a single computer located at the
                    artists’ studio, this first iteration of the work will
                    eventually fade from view when Stage 4 Restrictions have
                    been lifted in Melbourne. This real time event provides a
                    finite – if unpredictable – duration for a work that creates
                    space for audiences to experience both alone, as well as in
                    connection with others, in a purely virtual sphere; at a
                    time when our physical worlds are necessarily limited.
                </p>
                <p>
                    <em>How much time do we have?</em> includes both written and
                    audiated text. Through conversations with consultants Andy
                    Slater and Fayen d’Evie (Society of Visually Impaired Sound
                    Artists), and Will McCrostie (Description Victoria), Flynn
                    and Humphrey engaged with the principles and techniques of
                    audio description and alt-text, typically used for real
                    world performance experiences and web accessibility for
                    blind and low vision people, and then allowed these
                    principles to inform the scripting choices within the work:
                    combining descriptions about the overall visual landscape,
                    and details about close-focus movements, with poetic
                    observations and insights as to what the experience of the
                    work may evoke for the audience. The text has also been
                    informed by ongoing conversations with Noongar artist,
                    writer and academic Cassie Lynch, whose research into the
                    connections between Noongar storytelling and climate
                    ecologies have informed the artists’ approach to the
                    experience of time within the work.
                </p>
                <p>
                    Currently, there are no alt-text readers available for
                    moving image that is generated live. In creating the
                    audiated text for <em>How much time do we have?</em>, Flynn
                    and Humphrey adapted a machine-generated voice with an
                    Australian accent in an ambiguous mid-frequency pitch range
                    – a choice that recognises the cultural specificities and
                    assumptions inherent within existing technologies.
                </p>
                <p>
                    <em>How much time do we have?</em> is made to be experienced
                    on multiple devices and in various ways – for prolonged
                    durations or short bursts, to be returned to at different
                    times of day or night, with headphones or speakers, on a
                    phone that registers the text illegible or on a large,
                    absorbing screen. These different experiences draw attention
                    to the multitude of contexts in which we individually find
                    ourselves enduring a global pandemic: alone, in parallel,
                    across different time zones and cultural understandings of
                    time. In this sense, the work may be conceived of as a
                    clock: marking time, coming into focus, fading from view,
                    and waiting to reappear at a point in the uncertain future.
                </p>
                <p>
                    Madeleine Flynn and Tim Humphrey are Australian audio
                    conceptual artists who create unexpected situations for
                    listening. Their work is driven by a curiosity about sound
                    in culture and seeks to evolve and engage with new processes
                    and audiences, through public and participative
                    interventions. Recent presentations include AsiaTopa,
                    Melbourne: Setouchi Triennale Japan: Theater Der Welt
                    Germany: Brighton Festival UK: Sonica Festival Glasgow:
                    Asian Arts Theater, Gwangju: Perth Festival Australia: MONA
                    FOMA Australia: and ANTI Festival Finland. Current areas of
                    interest are the sound of existential risk, the audio agents
                    of artificial intelligence in public space, and long form
                    socially engaged public art interventions. They live in
                    Naarm/Melbourne, Australia.
                </p>
                <p style={{ textIndent: 0, marginTop: "1rem" }}>TEAM</p>
                <p style={{ textIndent: 0, marginTop: "1rem" }}>
                    Consultants
                    <br />
                    Stephen Banham, Letterbox
                    <br />
                    Fayen d’Evie and Andy Slater Society of Visually Impaired
                    Sound Artists (SoVISA)
                    <br />
                    Cassie Lynch <br />
                    Rowan McNaught
                    <br />
                    Will McCrostie, Description Victoria
                    <br />
                    Erin Milne, Bureau of Works{" "}
                </p>

                <p style={{ textIndent: 0, marginTop: "1rem" }}>
                    Thanks to
                    <br />
                    Annika Kristensen: Cryptic, Scotland: Cove Park, Scotland:
                    Substation, Melbourne: Rinske Ginsberg and the VCA Class of
                    2020, and the Creators Fund.
                </p>

                <p style={{ textIndent: 0, marginTop: "1rem" }}>
                    This project is offset via 15trees.com.au.
                </p>
            </>
        ),
        content: (
            <PageAlign>
                <MaddyTim />
            </PageAlign>
        ),
    },
    {
        active: true,
        name: "Archie Barry",
        date: "30 Sep",
        title: "Multiply",
        slug: "archie-barry",
        menuName: (
            <>
                Archie&nbsp;
                <br />
                Barry
            </>
        ),
        theme: ["#b7b7b7", "#222"],
        content: (
            <PageAlign>
                <Archie />
            </PageAlign>
        ),
        about: (
            <>
                <h1>
                    <em>Multiply</em> 2020
                    <br />
                    Archie Barry
                    <br />
                    from 30 September
                </h1>
                <p>
                    <em>Multiply</em> is a conversation about human
                    self-determination and interdependence played out between
                    five different personas. Acting as a score to imagery that
                    doesn’t exist, the work is located in disparate times,
                    places and politics and positioned in contrast to ocular
                    consumption. These interweaving stories are embedded in the
                    work. They are neither literal nor metaphorical; they are
                    sense impressions collected from places to which the artist
                    has been and interactions they have had, fractured and
                    rearranged into a five-track score. Very few human
                    experiences are orderly and this work communicates affects
                    of displacement and confusion.
                </p>

                <p>
                    A sensory excursion into domestic and digitised life,{" "}
                    <em>Multiply</em> variously asks: what is human
                    self-determination? Can we value imagination and
                    multiplicity as necessary to political reform and survival?
                    This sound work offers a contemplation on disconnection,
                    using voice and textural elements to simulate touch.{" "}
                </p>
                <p>
                    Archie Barry is an interdisciplinary artist currently living
                    and working in Melbourne, whose artistic research is
                    disseminated nationally and internationally in the form of
                    exhibitions, performances, presentations and workshops, and
                    their writing is featured in numerous publications.
                    Significantly, their artistic practice is richly informed
                    and complemented by lived experience. Consequently, Barry’s
                    work takes form as an autobiographical, somatic, process-led
                    multidisciplinary artistic practice spanning performance,
                    video, music composition and writing modalities. Themes of
                    personhood, embodiment, gender and mortality are
                    experientially theorised through sustained moments of
                    intense affective connection with audiences, often created
                    through strategies of disquieting and uncanny bodily
                    gestures, doubled voices, de-formed and re-formed language
                    and the production of multiple digital personas. Barry
                    completed a Masters of Contemporary Art at Victorian College
                    of the Arts (University of Melbourne) in 2017 and a Bachelor
                    of Art Education with First Class Honours at the College of
                    Fine Arts (University of New South Wales) in 2013.
                    <br />
                    <br />
                </p>
                <p style={{ textIndent: 0 }}>
                    Acknowledgements
                    <br />
                    This work was made on Wurundjeri land and is informed by
                    conversations and experiences that took place on Woi
                    Wurrung, Boonwurrung, Wathaurong, Seneca, Dakota, Lakota and
                    Ojibwa lands. Indigenous sovereignty prevails.
                    <br />
                    <br />
                </p>

                <p style={{ textIndent: 0 }}>
                    Composition, instrumentation and lyrics:
                    <br />
                    Archie Barry
                    <br />
                    <br />
                </p>
                <p style={{ textIndent: 0 }}>
                    Granular synthesis:
                    <br />
                    Isla Scott
                    <br />
                    <br />
                </p>
                <p style={{ textIndent: 0 }}>
                    Project consultants:
                    <br />
                    Meri Leeworthy, Daniel Jenatsch, Thembi Soddell
                    <br />
                    <br />
                </p>
                <p style={{ textIndent: 0 }}>
                    Mastering:
                    <br />
                    Ruby Burns
                    <br />
                    <br />
                </p>
            </>
        ),
    },
    {
        active: true,
        name: "Léuli Eshrāghi",
        date: "30 Sep",
        title: "AOAULI",
        slug: "leuli-eshraghi",
        content: (
            <PageAlign>
                <Leuli />
            </PageAlign>
        ),
        menuName: (
            <>
                Léuli&nbsp;
                <br />
                Eshrāghi
            </>
        ),
        theme: ["#CD2567", "#fff"],
        about: (
            <>
                <h1>
                    <em>AOAULI</em> 2020
                    <br />
                    Léuli Eshrāghi
                    <br />
                    from 30 September
                </h1>
                <p>
                    <em>AOAULI</em>, meaning midday in gagana Sāmoa, is part of
                    a new series of animated barkcloth, known as{" "}
                    <em>siapo viliata</em>. Conceiving the screen as a futurist
                    loom, Léuli Eshrāghi’s new digital work comes to us in 2020
                    from the ‘recent future’ of 2025 to explore relationships to
                    time, space, pleasure, memory and knowledge.
                </p>

                <p>
                    <em>AOAULI</em> comprises new writing, moving image and
                    animated drawings reflecting Eshrāghi’s growing artistic
                    engagement with Indigenous futurisms, data sovereignty,
                    digital literacy, non-colonial and decolonial museology and
                    citizen-driven cultural memory initiatives. As a digital
                    platform and online archive – bringing together ancestral
                    knowledge, drawings, epic poetry, manifestos and performance
                    videos of shimmering, glistening bodies – <em>AOAULI</em>{" "}
                    animates the idea of barkcloth as mnemonic device, and as a
                    decolonial model for digital archives and museums as meeting
                    places and spaces of cultural memory and knowledge-keeping.{" "}
                </p>

                <p>
                    Dr Léuli Eshrāghi is a Sāmoan-Persian-Australian artist,
                    curator and researcher committed to centring Indigenous
                    presence and power, sensual and spoken languages, and
                    ceremonial-political practices. Through performance, moving
                    image, writing and installation, Eshrāghi engages with
                    Indigenous possibility as haunted by ongoing ‘militourist’
                    and missionary violence that erase faʻafafine-faʻatama from
                    kinship structures. Eshrāghi is the inaugural
                    Horizon/Indigenous Futures postdoctoral fellow at Concordia
                    University and a member of The Space Between Us, a Social
                    Sciences and Humanities Research Council of Canada project
                    (2020-28) led by Dr Julie Nagam.
                </p>
                <p
                    style={{
                        marginTop: "2rem",
                        maxWidth: "none",
                        fontSize: "0.8rem",
                        textIndent: 0,
                    }}
                >
                    Image:
                    <br />
                    Léuli Eshrāghi, <em>re(cul)naissance</em> 2020 (still)
                    <br />
                    installation view, NIRIN, 22nd Biennale of Sydney, 2020
                    <br />
                    Commissioned by the Biennale of Sydney; courtesy the artist
                </p>
            </>
        ),
    },
    {
        active: true,
        name: "Sean Peoples",
        title: "OFFWORLD",
        date: "28 Oct",
        menuName: (
            <>
                Sean&nbsp;
                <br />
                Peoples
            </>
        ),
        slug: "sean-peoples",
        theme: ["#111", "#fff"],
        content: (
            <PageAlign>
                <Sean />
            </PageAlign>
        ),
        about: (
            <>
                <h1>
                    <em>OFFWORLD</em> 2020
                    <br />
                    Sean Peoples
                    <br />
                    digital video, endless duration
                    <br />
                    Courtesy the artist and STATION, Melbourne and Sydney
                </h1>
                <p>
                    Sean Peoples’ <em>OFFWORLD</em> is a compilation of
                    fragmented, and sometimes jarring, animated films employing
                    visual tropes from film, television and the internet.
                    Drawing upon cosmology and the digital archive as an open
                    source for speculative fiction and historical
                    re-interpretation, Peoples has constructed a metaphysical
                    galaxy, in which free-floating signifiers, imbued with
                    cultural politics, drift through space and gather together
                    as a melancholy <em>mise-en-scene</em> of collected
                    artefacts, arranged in the form of filmic{" "}
                    <em>memento mori</em>. Informed by the elasticity and
                    warping of time and space experienced during lockdown and
                    inherent to screen culture, <em>OFFWORLD</em> is programmed
                    to play on an autonomous and endless loop with a backdated
                    start time of 1 January 1901; a new remnant artefact of an
                    uncomfortably ever-present nowness, stretching back almost
                    four billion seconds to Australian Federation.
                </p>
                <p>
                    As the artist notes: “There is a popular belief that ancient
                    Greek cosmology was grounded in the idea of a symmetrical
                    universe; a spherical, stationary Earth at the centre from
                    which the Sun, Moon, and planets rotated. It followed that
                    this symmetry would extend to the presence of two or more
                    landmasses which would balance out the known habitable
                    world. Without a southern land mass (or Australia as it was
                    later named) it was thought an unbalanced Earth would topple
                    over in a vast and inharmonious universe. <em>OFFWORLD</em>{" "}
                    uses this theory as a metaphor to explore the increased
                    sense of imbalance and unease in the modern world –
                    particularly from an Australian perspective”.
                </p>
                <p>
                    Sean Peoples is a multidisciplinary artist with an interest
                    in imitation, appropriation and collage. Informed by
                    extensive research, his work seeks to integrate and parallel
                    disparate ideas and concerns, often employing networks and
                    models as a visual device. Peoples is also one half of The
                    Telepathy Project, a collaboration formed in 2005 with
                    artist Veronica Kent. Within their practice telepathy serves
                    as an extended metaphor and working methodology through
                    which they explore alternate ways of being, communicating
                    and collaborating. Selected exhibitions include{" "}
                    <em>Cubism, Tourism, Surrealism</em>, STATION, Melbourne,
                    2019; <em>Alien Antique</em>, TCB, Melbourne, 2016;{" "}
                    <em>Primavera</em>, Museum of Contemporary Art, Sydney,
                    2014; <em>Melbourne Now</em>, National Gallery of Victoria,
                    2013; and the televisual project, <em>Channel G</em>, West
                    Space, Melbourne, 2013.
                </p>
            </>
        ),
    },
    {
        active: true,
        date: "28 Oct",
        name: "Zanny Begg",
        title: "Magic Mountains",
        slug: "zanny-begg",
        menuName: (
            <>
                Zanny&nbsp;
                <br />
                Begg
            </>
        ),
        content: (
            <PageAlign>
                <Zanny />
            </PageAlign>
        ),
        theme: ["#040B00", "#fff"],
        about: (
            <>
                <h1>
                    <em>Magic Mountains</em> 2020
                    <br />
                    Zanny Begg
                    <br />
                    from 28 October
                </h1>
                <p>
                    <em>Magic Mountains</em> is a five-part narrative video
                    developed by Zanny Begg in response to the COVID-19
                    pandemic. <em>Magic Mountains</em> draws on family and
                    literary history as a basis from which to explore themes of
                    isolation and sickness, and, as the artist notes, “the
                    urgencies and anxieties over our current world:
                    immunity/contagion, solidarity/individualism, and
                    liberty/authoritarianism.”
                </p>
                <p>
                    The title of this work is drawn from the eponymous novel by
                    Thomas Mann, <em>The Magic Mountain</em> 1924. For Mann,
                    explains Begg, “isolation (and contagion) was a way to
                    detach from, and analyse, the sickness of a world that was
                    teetering towards war.” As Mann writes: “A person lives not
                    only their personal life, as an individual, but also,
                    consciously or unconsciously, the life of their epoch."
                </p>
                <p>
                    <em>Magic Mountains</em> loosely connects the themes of this
                    book with the life and death of Bernard Patrick Murray, the
                    artist's great-grandfather in law, one of many shunned by
                    society as contagious, and quarantined against his will at
                    the tuberculosis hospital known as Waterfall Sanatorium. The
                    sanatorium had been established in 1909 near the New South
                    Wales town of Helensburgh, forty-five kilometres south of
                    Sydney, in an attempt to manage the virus, and was left
                    abandoned when it closed in 1958 following medical advances
                    in disease prevention.
                </p>
                <p>
                    <em>Magic Mountains</em> follows Begg’s daughter Saorise as
                    she searches deep in the overgrown bush surrounding
                    Waterfall Sanatorium for the grave of Murray, her
                    great-great-grandfather, who, like the sanatorium’s
                    two-thousand other victims of tuberculosis, was buried at
                    the Helensburgh site.
                </p>
                <p>
                    The five vignettes of <em>Magic Mountains</em> can be viewed
                    in any order, covering themes of forgetting, trespassing,
                    ghosting, healing and remembering. Featuring characters of
                    the past and present, the work, as Begg notes, activates
                    “the ghosts of ancestors and connects the experiences of
                    pandemics across the generations.”
                </p>
                <p>
                    This artwork is best experienced through Google Chrome, it
                    includes sound as an important component, viewers are
                    encouraged to use headphones or speakers.
                </p>
                <p>
                    Zanny Begg lives in Bulli, on the lands of the Wodi Wodi
                    people, who are a part of the Dharawal Nation, and is an
                    artist and filmmaker who is interested in hidden and
                    contested histories. She works with film, drawing and
                    installation to explore ways in which we can live and be in
                    the world differently. She is the 2016 winner of the
                    Incinerator Art Award, Art for Social Change, the 2016
                    winner of the Terrence and Lynnette Fern Cite Residency
                    Paris and the 2018 winner of the inaugural ACMI and Artbank
                    film commission. She has exhibited widely in Australia and
                    overseas.
                </p>
                <p
                    style={{
                        fontSize: "1rem",
                        textIndent: 0,
                        marginTop: "1rem",
                    }}
                >
                    Permissions
                    <br />
                    Begg is grateful to the Illawarra Lands Council for
                    permission to film on Dharawal land. The Garrawarra burial
                    site is on unceded Aboriginal Land and contains the remains
                    of Aboriginal people who died of tuberculosis alongside many
                    others. Thanks to Uncle Peter Button for the smoking
                    ceremony.
                </p>
                <p
                    style={{
                        fontSize: "1rem",
                        textIndent: 0,
                        marginTop: "1rem",
                    }}
                >
                    The Garrawarra site does not allow public access. Permission
                    to film this work was granted by Wollongong Council and NSW
                    Department of Health.
                </p>
                <p
                    style={{
                        fontSize: "1rem",
                        textIndent: 0,
                        marginTop: "1rem",
                    }}
                >
                    Team
                    <br />
                    Camera: Josh Heath (Newcastle), Dane Howell (Garrawarra)
                    <br />
                    Sound: Jon Hunter
                    <br />
                    Camera Assistant: Ella Gibbens
                </p>
            </>
        ),
    },
];
