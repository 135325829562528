import React, { useRef, useEffect, Suspense, useState } from "react";
import { Canvas, useFrame, useLoader } from "react-three-fiber";
import { useWindowWidth } from "@react-hook/window-size";
import { TextureLoader } from "three";
import { motion } from "framer-motion";
import styled from "styled-components";

import EarthTex from "./Sean/EarthSm.jpg";

import { ContentBox, ActionButton } from "./_Shared";

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
`;

const Box = ({ setOpacity, ...props }) => {
    const mesh = useRef();

    const earthMaterial = useLoader(TextureLoader, EarthTex);

    useEffect(() => {
        mesh.current.rotation.y = mesh.current.rotation.y += 1.75;
        mesh.current.rotation.x = mesh.current.rotation.x -= 0.4;
        setOpacity(1);
    }, [mesh, setOpacity]);

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => {
        mesh.current.rotation.y = mesh.current.rotation.y += 0.0005;
        mesh.current.rotation.x = mesh.current.rotation.x += 0.00005;
    });

    return (
        <mesh {...props} ref={mesh}>
            <sphereBufferGeometry attach="geometry" args={[1, 64, 64]} />
            <meshBasicMaterial
                attach="material"
                map={earthMaterial}
                roughness={1}
            />
        </mesh>
    );
};
export default () => {
    const windowWidth = useWindowWidth();
    const [opacity, setOpacity] = useState(0);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: opacity }}
            transition={{ duration: 4, delay: 1 }}
        >
            <Overlay>
                <Canvas
                    colorManagement={false}
                    camera={{ fov: windowWidth > 768 ? 34 : 45 }}
                    pixelRatio={window.devicePixelRatio}
                >
                    <Suspense
                        fallback={
                            <boxBufferGeometry
                                attach="geometry"
                                args={[1, 1, 1]}
                            />
                        }
                    >
                        <Box setOpacity={setOpacity} position={[0, 0, 0]} />
                    </Suspense>
                </Canvas>
            </Overlay>
            <>
                <ContentBox
                    style={{ height: windowWidth > 768 ? "40vh" : "60vh" }}
                />
                <ActionButton
                    style={{ maxWidth: "10rem", margin: "0 auto" }}
                    href="https://offworld.tv"
                    target="_blank"
                    tabIndex="1"
                >
                    Go OFFWORLD
                </ActionButton>
            </>
        </motion.div>
    );
};
